import React from 'react';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import {getPdfText} from './api.js';
import bpgPdf from './pdfs/BelairParkGuide.pdf'
import bpmPdf from './pdfs/BelairParkMap.pdf'
import brunspmPdf from './pdfs/BrunswickParkMap.pdf'
import cgmPdf from './pdfs/CamberwellGreenMap.pdf'
import dpgPdf from './pdfs/DulwichParkGuide.pdf'
import dpmPdf from './pdfs/DulwichParkMap.pdf'
import lggPdf from './pdfs/LucasGardensGuide.pdf'
import lgmPdf from './pdfs/LucasGardensMap.pdf'
import prgPdf from './pdfs/PeckhamRyeGuide.pdf'
import rpgPdf from './pdfs/RuskinParkGuide.pdf'
import sgcmPdf from './pdfs/StGilesChurchyardMap.pdf'
import sggPdf from './pdfs/SunrayGardensGuide.pdf'
import sgmPdf from './pdfs/SunrayGardensMap.pdf'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    spacing: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paragraph: {
    spacing:1,
  }
}));

const DownloadPdf = (props: setPathway) => {
  const lookingForText = React.useRef(false);
  const [pdfText, setPdfText] = React.useState(null);
  const [refresh, setRefresh] = React.useState(true);
  // console.log("In DownloadPdf!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  var textTop = 10;

  const handleClose = () => { props.setPathway("about");
  // console.log ('In handleClose, setting state to return to AboutPark')
  };

  const getText = async () => { 
    // console.log ('In getText', pdfText); 
    setPdfText(await getPdfText());
    // console.log('Leaving getText with', pdfText);
  }

  const handleClick = () => {
      console.log('Trying to refresh');
      setRefresh(!refresh);
  }

  const classes = useStyles();
  // const theme = {spacing:20};

// console.log('About to get pdfText');

  if (!lookingForText.current) {
    // console.log('Going to get pdfText', pdfText, lookingForText);
    lookingForText.current = true;
    // console.log('Going to get pdfText', pdfText, lookingForText);
    getText();
    // console.log('getText called');
  };

  // console.log('pdfText', pdfText);
  if (!pdfText) {
    return (null)
  } else {
    return (
      <>
        <AppBar key="appBar" position='fixed' text={''}> 
          <Toolbar key="toolbar">
            
            <Typography key="typography1" variant="h6" align="center" className={classes.title} >
              {'Download park PDFs'} 
            </Typography>

            <Button key="button" variant="contained" onClick={handleClose} > Back </Button>
          </Toolbar>
        </AppBar>

        <Box key="box1" className={classes.root} paddingTop={textTop} pl={1}>
          {pdfText.map((infoString) => (
            <div key="div" className={classes.root}>
              <Typography key="typography2" className={classes.root} variant="body2" align="left" gutterBottom={true} paragraph={true}> {infoString} </Typography>
            </div>
          ) ) } 
        </Box>

        <Box key="bpg" m={1} p={1}>        
          <Button key="bpgButton" id="dpgButton" variant="contained" onClick={handleClick}> <a href={bpgPdf} download="BelairParkGuide.pdf"> Belair Park Guide (7MB)</a>  </Button>
        </Box>

        <Box key="bpm" m={1} p={1}>        
          <Button key="bpmButton" id="bpmButton" variant="contained" onClick={handleClick}> <a href={bpmPdf} download="BelairParkMap.pdf"> Belair Park Map (1MB)</a> </Button>
        </Box>

        <Box key="brunspm" m={1} p={1}>        
          <Button key="brunspmButton" id="brunspmButton" variant="contained" onClick={handleClick}> <a href={brunspmPdf} download="BrunswickParkMap.pdf"> Brunswick Park Map (3MB)</a>  </Button>
        </Box>

        <Box key="cgm" m={1} p={1}>        
          <Button key="cgmButton" id="cgmButton" variant="contained" onClick={handleClick}> <a href={cgmPdf} download="CamberwellGreenMap.pdf"> Camberwell Green Map (2MB)</a> </Button>
        </Box>

        <Box key="dpg" m={1} p={1}>        
          <Button key="dpgButton" id="dpgButton" variant="contained" onClick={handleClick}> <a href={dpgPdf} download="DulwichParkGuide.pdf"> Dulwich Park Guide (2MB)</a>  </Button>
        </Box>

        <Box key="dpm" m={1} p={1}>        
          <Button key="dpmButton" id="dpmButton" variant="contained" onClick={handleClick}> <a href={dpmPdf} download="DulwichParkMap.pdf"> Dulwich Park Map (1MB)</a> </Button>
        </Box>

        <Box key="lgg" m={1} p={1}>        
          <Button key="lggButton" id="lggButton" variant="contained" onClick={handleClick}> <a href={lggPdf} download="LucasGardensGuide.pdf"> Lucas Gardens Guide (4MB)</a>  </Button>
        </Box>

        <Box key="lgm" m={1} p={1}>        
          <Button key="lgmButton" id="lgmButton" variant="contained" onClick={handleClick}> <a href={lgmPdf} download="LucasGardensMap.pdf"> Lucas Gardens Map (2MB)</a> </Button>
        </Box>

        <Box key="prg" m={1} p={1}>        
          <Button key="prgButton" id="prgButton" variant="contained" onClick={handleClick}> <a href={prgPdf} download="PeckhamRyeMap.pdf"> Peckham Rye Map (3MB)</a> </Button>
        </Box>

        <Box key="rpg" m={1} p={1}>        
          <Button key="rpgButton" id="rpgButton" variant="contained" onClick={handleClick}> <a href={rpgPdf} download="RuskinParkGuide.pdf"> Ruskin Park Guide (2MB)</a>  </Button>
        </Box>

        <Box key="sgcm" m={1} p={1}>        
          <Button key="sgcmButton" id="sgcmButton" variant="contained" onClick={handleClick}> <a href={sgcmPdf} download="StGilesChurchyardMap.pdf"> St Giles Churchyard Map (2MB)</a> </Button>
        </Box>

        <Box key="sgg" m={1} p={1}>        
          <Button key="sggButton" id="sggButton" variant="contained" onClick={handleClick}> <a href={sggPdf} download="SunrayGardensGuide.pdf"> Sunray Gardens Guide (5MB)</a>  </Button>
        </Box>

        <Box key="sgm" m={1} p={1}>        
          <Button key="sgmButton" id="sgmButton" variant="contained" onClick={handleClick}> <a href={sgmPdf} download="SunrayGardensMap.pdf"> Sunray Gardens Map (2MB)</a> </Button>
        </Box>
      </>
    );    }
 
}

export default DownloadPdf