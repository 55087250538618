import React from 'react';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {getAllParkNames} from './api.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    spacing: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paragraph: {
    spacing:1,
  }
}));


const AboutPark = (props: parkInfo, setPathway) => {
  // console.log("In AboutPark!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
  // console.log(props.parkInfo);
  // let mItem = '';
  const [menuItems, setMenuItems] = React.useState(null);
  const s = props.parkInfo.parkDisplayName;
  const parent = props.parkInfo.parentPark;
  const subTrails = props.parkInfo.parkSubTrails;
  var textTop = 1;
  if (subTrails==='') {textTop = 10};
  // console.log('menuItems', menuItems, menuItems.value);
  // console.log('SubTrails', subTrails);

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);

  const lookingForMenu = React.useRef(false);

  // setSubTrails(null);

  const handleMenuClick = (event) => { 
    setAnchorEl1(event.currentTarget);
    // console.log('Change button clicked');
  };
  
  const handleMenuSelect = (event) => {
    // console.log('Menu closed with ', event.target.firstChild.data, ' selected');
    setAnchorEl1(null);
    props.setPathway(">" + event.target.firstChild.data)
  };


  const handleClose = () => { props.setPathway("map");
  // console.log ('In handleClose, setting state to return to grid')
  };

  const handleAbout = () => { props.setPathway("parktrees");
  // console.log ('In handleClose, setting state to go to AboutParktrees')
  };

  const handlePdf = () => { props.setPathway("pdf");
  // console.log ('In handleClose, setting state to go to AboutParktrees')
  };

  const handleSubTrailClick = async (event) => { 
    setAnchorEl2(event.currentTarget);
    // console.log('Subtrail button clicked');
    // props.setPathway(">"+props.parkInfo.parentPark);
  // console.log ('In handleClose, setting state to go to AboutParktrees')
  }

  const handleSubTrailSelect = (event) => {
    // console.log('Menu closed with ', event.target.firstChild.data, ' selected');
    setAnchorEl2(null);
    props.setPathway(">" + event.target.firstChild.data)
  };


  const getMenuItems = async () => { 
    // console.log ('In getMenuItems', menuItems); 
    setMenuItems(await getAllParkNames());
    // console.log('Leaving getMenuItems with', menuItems);
  }

  const classes = useStyles();
  // const theme = {spacing:20};

// console.log('About to get menu items, lookingForMenu, lookingForSubTrails', lookingForMenu,lookingForSubTrails);

  if (!lookingForMenu.current) {
    // console.log('Going to get menu items, lookingForMenu', lookingForMenu);
    lookingForMenu.current = true;
    // console.log('Going to get menu items, lookingForMenu', lookingForMenu);
    getMenuItems();
    // console.log('getMenuItems called');
  };


  // console.log('MenuItems are', menuItems, '  SubTrails are ', subTrails);
  // console.log('textTop ', textTop);

  if (!menuItems) {
    return (null)
  } else {
    return (
      <>
        <AppBar key="appBar" position='fixed' text={s}> 
          <Toolbar key="toolbar">
            <Button key="menuButton" id="menuButton" variant="contained" onClick={handleMenuClick} > Parks </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl1}
              open={open1}
              onClose={handleMenuSelect}
              MenuListProps={{
              'aria-labelledby': 'menuButton',
              }}
            >
              {menuItems.map((mItem, index) => (
                <MenuItem key={index} onClick={handleMenuSelect}>{menuItems[index]}</MenuItem>
              ))}
            </Menu>

            <Typography key="typography1" variant="h6" align="center" className={classes.title} >
              {s} 
            </Typography>

            <Button key="button" variant="contained" onClick={handleClose} > Map </Button>
          </Toolbar>
        </AppBar>

        {subTrails!=='' &&
          <Box key="subTrailBox" m={1} p={1} paddingTop={8} pl={1}>        
            <Button key="subTrailButton" id="subTrailButton" variant="contained" onClick={handleSubTrailClick} > More trails in {parent} </Button>
            <Menu
              id="basic-menu2"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleSubTrailSelect}
              MenuListProps={{
              'aria-labelledby': 'menuButton',
              }}
            >
              {subTrails.map((mItem, index) => (
                <MenuItem key={index} onClick={handleSubTrailSelect}>{subTrails[index]}</MenuItem>
              ))}
            </Menu>
          </Box>
        }

        <Box key="box1" className={classes.root} paddingTop={textTop} pl={1}>
          {props.parkInfo.parkText.map((infoString, index) => (
            <div key={index} className={classes.root}>
              <Typography key="typography2" className={classes.root} variant="body2" align="left" gutterBottom={true} paragraph={true}> {infoString} </Typography>
            </div>
          ) ) } 
        </Box>

        <Box key="showFriends" m={1} p={1} align="left">        
          <a href={props.parkInfo.friendsUrl} >Go to the Friends of {props.parkInfo.parentPark} website.</a> 
        </Box>
  
        <Box key="showPdf" m={1} p={1}>        
          <Button key="pdfButton" id="pdfButton" variant="contained" onClick={handlePdf} > Get guides and maps as PDFs </Button>
        </Box>
  
        <Box key="aboutBox" m={1} p={1}>        
          <Button key="aboutButton" id="aboutButton" variant="contained" onClick={handleAbout} > About Parktrees.online </Button>
        </Box>
    </>
    );    }
 
}

export default AboutPark