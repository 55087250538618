import './showMap.css';
import React, { useState } from 'react';
import { useMap, useMapEvents, MapContainer, MapConsumer, TileLayer, Marker, Tooltip, CircleMarker } from 'react-leaflet'  ;
import UseControl from './reactControl.js';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Stack from '@mui/material/Stack';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrongRounded';
import { ListItemSecondaryAction } from '@material-ui/core';



export const ShowMap = (props: parkInfo, setPathway, currentZoom, setCurrentZoom, mapCentre, setMapCentre) => {
    const [circleCentre, setCircleCentre] = useState(props.parkInfo.parkLocation)
    // const [circleRadius, setCircleRadius] = useState(8)
    const [recentre, setRecentre] = useState(false)
    const [locationError, setLocationError] = useState(false)
    const circleRadius = 8
    const iconSide = 40;
    // const recentre = useRef(false);

    // console.log('In ShowMap', props.mapCentre);

    function LocationMarker() {
        // console.log('In LocationMarker')
        const map = useMapEvents({
            locationfound(e) {
            // console.log('location found', e);
            // console.log('position', e.latitude, e.longitude);
            setLocationError(false);
            if (circleCentre[0]!==e.latitude || circleCentre[1]!==e.longitude) {
                // console.log('Position changed', circleCentre[0], circleCentre[1], 'to', e.latitude, e.longitude);
                setCircleCentre ([e.latitude, e.longitude]);
                // setCircleRadius(8);
            } else { 
                console.log(e);
                // console.log('no change in position');
             }
          },
          locationerror(e) {
            // console.log('Event location error')
            // we no longer use this state
            setLocationError(true);
        },
        })
        return(null);
    }

    const handleClose = (e) => {
        console.log(e);
        props.setPathway("about")
    }
    
    const handleRecentre = () => {
        setRecentre(true);
    }
    
    function Recentre() {
        // console.log('In Recentre, props.mapCentre, recentre, circleCentre', props.mapCentre, recentre, circleCentre);
        setRecentre(false);
        const m = useMap();
        m.setView(circleCentre)
        return null
    }
    
    function ZoomHandler(){
        const map = useMapEvents({
            zoomend:(e) => {
                // console.log(e);
                props.setCurrentZoom(e.target._zoom);
            }
         }) 
        return null;    
    }
    
    function MoveHandler(){
        const m = useMap();
        const map = useMapEvents({
            moveend:(e) => {
                // console.log(e);
                // console.log(m.getCentre());
                props.setMapCentre(m.getCenter());
            }
        })  
        return null    
    }

    return( 
        <MapContainer center={props.mapCentre} zoom={props.currentZoom} scrollWheelZoom={false} >
            <MapConsumer>
                {(map) => {
                    if (!locationError) {map.locate({watch:true, enableHighAccuracy:true})}
                    return null
                }}
            </MapConsumer>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {props.parkInfo.trees.map((parkTree) => (
                <Marker key={parkTree.id} position={parkTree.treeLocation} eventHandlers={{ click: (e) => {props.setPathway('!'+parkTree.id)}}} >
                    <Tooltip permanent={true} interactive={true} direction="bottom" offset={[-15, 25]} > 
                        {parkTree.commonName}
                    </Tooltip>
                 </Marker>
            ))}
            <CircleMarker center={circleCentre} radius={circleRadius} color="red" pane="popupPane" />
            {/* {!locationError && <Circle center={circleCentre} radius={circleRadius} color="red" />} */}
            <UseControl key='buttons' >
                <Stack spacing={1} direction="rows" alignItems="center" justifyContent="flex-end"  >
                    <Button key='back' variant="contained" color="primary" size="small" fontSize="small" 
                        onClick={handleClose} > {props.parkInfo.parkDisplayName} ...</Button>
                    <IconButton key='find_me' variant="contained" onClick={handleRecentre} >
                        <CenterFocusStrongIcon sx={{width: iconSide, height: iconSide, borderRadius: 0, color: "success" }}/>
                    </IconButton>
                </Stack>
            </UseControl>
            <LocationMarker />
            <ZoomHandler />
            <MoveHandler />
            {recentre && <Recentre />}
         </MapContainer>       
     )
}
