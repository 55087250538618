import React from 'react';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import ShowImages from './showImages.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    spacing: 2,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paragraph: {
    spacing:1,
  }
}));

const AboutTree = ({id, parkInfo, setPathway}) => {

  // const [imageReady, setImageReady ] = useState(null);

  // console.log("In AboutTree with id, parkInfo", id, parkInfo)
  // let treeId = _id.slice(1, _id.length)
  // let returnCode = _id.slice(0, 1)
    
  const handleClose = () => { 
    // console.log ('In handleClose, setting state to return to map');
    setPathway("map");
  }

  const classes = useStyles();
  // const theme = {spacing:20};
  let specificTree = parkInfo.trees.find(tree=>tree.id === id)
  // console.log('id, tree', id, specificTree)
  // let extraInfo = ['']
  // if (returnCode==='!') {extraInfo = specificTree.instanceInfo} 
  // console.log('treeInstance', specificTree)
  // console.log('extraInfo', extraInfo)
  if (parkInfo) {  
    return (
      <>  
        <AppBar position='fixed' > 
          <Toolbar>
            <Typography variant="h5" align="center" className={classes.title} >
              {specificTree.commonName} 
            </Typography>

            <Button variant="contained" onClick={handleClose} > Back </Button>
            </Toolbar>
        </AppBar>
        <Box className={classes.root} paddingTop={10} pl={1}>
          <Typography className={classes.root} variant="body2" align="left" gutterBottom={true} paragraph={true} >
                Latin name:  {specificTree.latinName}
          </Typography>
          {specificTree.treeInfo.map((s,index) => (
            <div className={classes.root} key={index}>
              <Typography className={classes.root} p={1} variant="body2" align="left" gutterBottom={true} paragraph={true} > {s} </Typography> 
            </div>  
          ) ) }
          {/* <Typography variant="body2" align="left" gutterBottom={true} paragraph={true} > {extraInfo} </Typography>  */}
        </Box>
        <ShowImages commonName = {specificTree.commonName} /> 
      </>
    )
  } else {return(null)}
}

export default AboutTree