import './App.css';
import React, { useRef, useState } from 'react';
import AboutPark from './aboutPark.js';
import AboutTree from './aboutTree.js';
import AboutParktrees from './aboutParktrees.js';
import Initialise from './initialise.js';
import {ShowMap} from './showMap.js';
import {getFirstPark, changePark} from './api.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import DownloadPdf from './downloadPdf.js';

function App() {
  // console.log('In App.js');

  const [pathway, setPathway] = useState("about");
  const [parkInfo, setParkInfo ] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(17);
  const [mapCentre, setMapCentre] = useState(null)
  const starting = useRef(true);

  window.addEventListener('load', function() {
    window.history.pushState({}, '')
  })
  
  window.addEventListener('popstate', function() {
    window.history.pushState({}, '')
  })

    if (starting.current) {
    // console.log('Starting!');
    starting.current = false;
    getFirstPark(setPathway, setParkInfo, setMapCentre, setCurrentZoom);
    // console.log('After initialisation, pathway', pathway, 'parkInfo ',parkInfo, 'mapCentre', mapCentre, 'pathway = ', pathway);
  };  
  
   // if (parkInfo) {console.log('parkName = ', parkName, 'parkInfo = ', parkInfo, 'pathway = ', pathway)}
  
  if (pathway.slice(0,1)==='>'){
    console.log('pathway is', pathway , ': starts with > so park needs changing');
    changePark(pathway, setPathway, setParkInfo, setMapCentre, setCurrentZoom);
  }
  // console.log('After changePark, pathway', pathway, 'parkInfo ',parkInfo, 'mapCentre', mapCentre);
  
  if (mapCentre == null && parkInfo) { 
    setMapCentre(parkInfo.parkLocation);
    // console.log('mapCentre set in main block?', mapCentre);
    // if (parkInfo) {changeMapCentre(parkInfo, setMapCentre)};
    // console.log('Setting mapCentre', parkInfo.parkLocation, mapCentre)
  }

  // console.log('Before rendering, pathway', pathway, 'parkInfo ',parkInfo, 'mapCentre', mapCentre, 'parkName = ', parkName, 'pathway = ', pathway);
return ( <>
    <CssBaseline />
    <div className="App">
          {pathway==="initialise" && <Initialise setPathway = {setPathway} /> }
          {pathway==="map" && parkInfo && <ShowMap parkInfo = {parkInfo} setPathway = {setPathway} currentZoom = {currentZoom} setCurrentZoom = {setCurrentZoom} mapCentre = {mapCentre} setMapCentre = {setMapCentre}/> }
          {pathway==="about" && parkInfo && <AboutPark parkInfo = {parkInfo}  setPathway = {setPathway} /> }
          {pathway==="parktrees" && parkInfo && <AboutParktrees setPathway = {setPathway} /> }
          {pathway==="pdf" && parkInfo && <DownloadPdf setPathway = {setPathway} /> }
          {pathway.slice(0,1)==="!" && <AboutTree id = {pathway.slice(1, pathway.length)} parkInfo = {parkInfo} setPathway = {setPathway} /> }
    </div>
  </>);
  
}

export default App;
