import React from 'react';

const TreeImage = (props: commonName) => {
    const fName = 'trees/' + props.commonName + '/tree.jpg';
    try {
        return (
            <div>
                <img src={fName} alt="" width="100%" />
            </div>
        )
    } 
    catch (error) {
        console.error(error);
        console.log('Error in TreeImage');  
        return {};
    }
}

const LeafImage = (props: commonName ) => {
    const fName = 'trees/' + props.commonName + '/leaf.jpg';
    try {
        return (
                <div>
                    <img src={fName} alt=""  width = "100%" />
                </div>
            )
    } 
    catch (error) {
        console.error(error);
        console.log('Error in LeafImage');  
        return {};
    }
}

const FlowerImage = (props: commonName ) => {
    const fName = 'trees/' + props.commonName + '/flowers.jpg';
    try {
        return (
                <div>
                    <img src={fName} alt=""  width = "100%" />
                </div>
            )
    } 
    catch (error) {
        console.error(error);
        console.log('Error in FlowerImage');  
        return {};
    }
}

const FruitImage = (props: commonName ) => {
    const fName = 'trees/' + props.commonName + '/fruit.jpg';
    try {
        return (
                <div>
                    <img src={fName} alt=""  width = "100%" />
                </div>
            )
    } 
    catch (error) {
        console.error(error);
        console.log('Error in FruitImage');  
        return {};
    }
}

const BarkImage = (props: commonName ) => {
    const fName = 'trees/' + props.commonName + '/bark.jpg';
    try {
        return (
                <div>
                    <img src={fName} alt=""  width = "100%" />
                </div>
            )
    } 
    catch (error) {
        console.error(error);
        console.log('Error in BarkImage');  
        return {};
    }
}

const ExtraImage = (props: commonName ) => {
    const fName = 'trees/' + props.commonName + '/extra.jpg';
    try {
        return (
            <div>
                <img src={fName} alt=""  width = "100%" />
            </div>
        )
    } 
    catch (error) {
        console.error(error);
        console.log('Error in ExtraImage');  
        return {};
    }
}

const ShowImages = (props: commonName ) => { return (
    <div>
        < TreeImage  commonName = {props.commonName}/>
        < LeafImage  commonName = {props.commonName}/>
        < FlowerImage  commonName = {props.commonName}/>
        < FruitImage  commonName = {props.commonName}/>
        < BarkImage  commonName = {props.commonName}/>
        < ExtraImage  commonName = {props.commonName}/>
    </div> )
}

export default ShowImages