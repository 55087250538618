import Cookies from 'universal-cookie';
const cookie = new Cookies();
const defaultParkName = 'Dulwich Park';
const defaultZoom = 18;

const writeCookie = (t) => {
    cookie.set('myPark', t, {path: '/', maxAge: 365*24*60*60});
    // console.log('Cookie written and checked: ', cookie.get('myPark'));
}

export const getFirstPark = async (setPathway, setParkInfo, setMapCentre, setCurrentZoom) => {
    var s = await readCookie();
    // console.log('In getFirstPark, cookie returned as ', s);
    if (s==='') {
        setPathway('initialise');
        return(null);
    }
    // console.log('Moving on to getAllParkInfo with', s);
    await getAllParkInfo(s, setParkInfo, setMapCentre, setCurrentZoom);
    // console.log('Setting pathway and good to return');
    setPathway('about');
}

export const changePark = async (pathway, setPathway, setParkInfo, setMapCentre, setCurrentZoom) => {
    var s = pathway.slice(1, pathway.length);
    // console.log('Moving on to getAllParkInfo with', s)
    // console.log('changePark; setting pathway to "about"');
    setPathway('about');
    setMapCentre(null);
    // setParkName(s);
    await getAllParkInfo(s, setParkInfo, setMapCentre, setCurrentZoom);
    // console.log('changePark: writing cookie as', s, 'and good to return.');
    await writeCookie(s);
}

export const readCookie = async () => {
    // console.log('In readCookie');

    var s = cookie.get('myPark');
    // console.log(s, 'read from cookie');
    const t = await getAllValidCookies();
    // console.log(t, 'read from getAllValidCookies');
    if (typeof s === 'undefined' || t.indexOf(s)<0) 
    {
        // console.log('Type of s is', typeof s, 'and index is', t.indexOf(s));
        // console.log('So cookie is undefined or corrupt');
        //   s = defaultParkName;
    //   writeCookie(s);
        s = '';
    }
    // fileNameReady.current = true;
    // console.log('Sending ', s, ' from readCookie');
    return(s);
}

export const getPdfText = async () => {
    // console.log('Entering getPdfText');
    const fName = 'parks/pdf.txt';
    // console.log(fName)
    const t1 = await fetch(fName);
    const t2 = await t1.text();
    // console.log('Leaving getPdfText with', t2);
    return t2.split('\n');
}

export const getAllParkNames = async () => {
    // console.log('Entering getAllParkNames');
    const fName = 'parks/parks.txt';
    // console.log(fName)
    const t1 = await fetch(fName);
    const t2 = await t1.text();
    // console.log('Leaving getAllParkNames with', t2);
    return t2.split('\n');
}

export const getAllValidCookies = async () => {
    // console.log('Entering getAllValidCookies');
    const fName = 'parks/valid_cookies.txt';
    // console.log(fName)
    const t1 = await fetch(fName);
    const t2 = await t1.text();
    // console.log('Leaving getAllValidCookies with', t2);
    return t2.split('\n');
}

export const getAboutParktrees = async () => {
    // console.log('Entering getAboutParkTrees');
    const fName = './about.txt';
    // console.log(fName)
    const t1 = await fetch(fName);
    const t2 = await t1.text();
    // console.log('Leaving getAboutParktrees with', t2);
    return t2.split('\n');
}

const getTreeText = async (commonName) => {
    try{
        // console.log('Entering getTreeText');
        const fName = 'trees/'+commonName+'/tree.txt';
        // console.log('getTreeText filename', fName)
        const t1 = await fetch(fName);
        const t2 = await t1.text();
        // console.log('TreeText ', t2.split('\n'))
        return t2.split('\n');
    }
        catch (error) {
        console.error(error);
        console.log('Error in getTreeText')  
        return {};
    }
}

export const getAllSubTrails = async (prefix) => {
    // console.log('Entered getAllSubTrails');
    if (prefix===''){
        // console.log('no subtrails in this park');
        return '';
    }
    try{
        // console.log('Entering getAllSubTrails for', prefix);
        const fName = 'parks/'+prefix;
        // console.log(fName + ' file list requested');
        const t1 = await fetch(fName);
        // console.log('t1 and status', t1, t1.status);
        if (t1.status!==200) {
            // console.log('Response code not 200, its', t1.status)
            throw Error(t1.statusText);
        }
        const t2 = await t1.text();
        // console.log('Leaving getAllSubtrails with', t2);
        return t2.split('\n');    
    }
    catch{
        // console.log('Error in getAllSubTrails - probably no file');
        // console.error(Error);
        return '';
    }
 }

    
export const getAllParkInfo = async (parkName, setParkInfo, setMapCentre, setCurrentZoom) => {

    const tree = {
        id: 1,
        commonName: 'tree',
        latinName: 'treeus',
        treeLocation: [0.0,0.0],
        treeInfo:['generic','specific'],
    }
    const parkInfo = {
        // parkFileName: 'fName',
        parkDisplayName: 'parkName',
        subTrailFileName: 'subTrailFileName',
        friendUrl: '',
        parkSubTrails: [''],
        parentPark: 'parent',
        parkText: [],
        parkLocation: [0.0, 0.0],
        trees: []
    }

    try {
        // console.log(parkName, ' received in getAllParkInfo');
        const fName = 'parks/'+parkName+'.txt';
        // parkInfo.parkFileName = fName
        // console.log('In getAllParkInfo looking for ', fName);
        const t1 = await fetch(fName);
        const t2 = await t1.text();
        const t3 = t2.split('\n');
        let n = t3.length;
        // console.log('t3', t3);
        let i = 0;
        // let i1 = 0;
        let i2 = 0;
        let i3 = 0;

        // Park text file first line is name, next two are LatLng
        parkInfo.parkDisplayName = t3[0];
        parkInfo.parentPark = t3[1];
        // console.log('Subtrails in', parkInfo.parentPark);
        if (t3[1]!==''){
            // console.log('Getting subtrails from', parkInfo.parentPark + '.subtrails')
            parkInfo.parkSubTrails = await (getAllSubTrails(parkInfo.parentPark+'.subtrails'));
        } else {
            parkInfo.parkSubTrails = '';
            parkInfo.parentPark = parkInfo.parkDisplayName;
        }
        // console.log('Subtrails are', parkInfo.parkSubTrails);
        parkInfo.friendsUrl = t3[2];
        parkInfo.parkLocation = [parseFloat(t3[3]), parseFloat(t3[4])];

        // Next follows an indeterminate number of paragraphs (the park information) followed by a blank line
        i = 5;
        while (t3[i] !== '') {
            parkInfo.parkText[i-4] = t3[i];
            i++;
        }
        i++;
        // console.log('i, t3[i], parkInfo, parkInfo.parkInfo', i, t3[i], parkInfo, parkInfo.parkInfo);

        // Each tree in the park.txt file now has its own 5 lines of text with other trees separated by blank lines
        for (i2=i; i2<n; i2=i2+6) {
            tree.id = t3[i2];
            tree.commonName = t3[i2+1];
            tree.treeLocation = [parseFloat(t3[i2+2]), parseFloat(t3[i2+3])];
            tree.treeInfo = await getTreeText(tree.commonName);
            // console.log(tree.treeInfo);
            tree.latinName = tree.treeInfo.shift(); 
            tree.treeInfo.push(t3[i2+4]);
            parkInfo.trees[i3] = {...tree};
            // console.log('Generic info', tree.treeInfo)
            // console.log('i2, i3, n, tree, parkInfo', i2, i3, n, tree, parkInfo);
            i3++;
        }

        // console.log('Leaving getAllParkInfo');
        // console.log('parkInfo', parkInfo);
        setMapCentre(parkInfo.parkLocation);
        setCurrentZoom(defaultZoom);
        setParkInfo(parkInfo);
        return;
    }
    catch (error) {
        // console.error(error);
        // console.log('Error in getAllParkInfo for', parkName);  
        return {};
    }
};

export const api = {
    getAllParkNames, getAboutParktrees, getFirstPark, changePark, getPdfText        // , getAllParkInfo, treeImageAddress, leafImageAddress, extraImageAddress
};

export default api;