import React from 'react';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import './App.css';
import {getAboutParktrees} from './api.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    spacing: 20,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paragraph: {
    spacing:1,
  }
}));


let s = "About parktrees.online";
const AboutParktrees = (props: setPathway) => {
  // console.log("In AboutParktrees")
  const [aboutText, setAboutText] = React.useState(null);

  const handleClose = () => { props.setPathway("about");
  // console.log ('In handleClose, setting state to return to grid')
  }

  const getText = async () => { 
    setAboutText ( await getAboutParktrees() );
    // console.log ('In getText', aboutText); 
  }

  const classes = useStyles();
  // const theme = {spacing:20};
  if (!aboutText) {getText()};
  if (aboutText===null) {return(null)}
    else { return (
    <>
      <AppBar key="appBar" position='fixed' text={s}> 
        <Toolbar key="toolbar">
          <Typography variant="h5" align="left" className={classes.title} >
            {s} 
          </Typography>

          <Button variant="contained" onClick={handleClose} > Back </Button>
        </Toolbar>
      </AppBar>
      <Box key="box1" className={classes.root} paddingTop={10} pl={1} >
        {aboutText.map((infoString) => (
            <div key="div" className={classes.root}>
              <Typography key="typography2" className={classes.root} variant="body2" align="left" gutterBottom={true} paragraph={true}> {infoString} </Typography>
            </div>
        ) ) } 
    </Box>
    </>
  )};
}

export default AboutParktrees